<template>
  <div class="d-inline-block social-icons">
    <div class="d-flex d-inline-block justify-content-start">
      <a @click.prevent="facebook" href="#">
        <img src="@/assets/images/logos/social-media/facebook.png" />
      </a>
      <a @click.prevent="tweet" href="#">
        <img src="@/assets/images/logos/social-media/twitter.png" />
      </a>
      <!--<a
        @click="incrementCounter('pinterest')"
        href="https://www.pinterest.com/pin/create/button/"
        data-pin-custom="true"
        data-pin-do="buttonBookmark"
      >
        <img src="@/assets/images/logos/social-media/pinterest.png" />
      </a>-->
      <!--<a @click.prevent="whatsapp" href="#">
        <img src="@/assets/images/logos/social-media/whatsapp.png" />
      </a>-->
    </div>
    <p class="pl-3 mb-0 share-count">
      <span class="light-grey">{{ count | roundDown }}+ shares</span>
    </p>

    <div v-show="false">
      <img v-for="image in images" :src="image" />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["post"],
  data() {
    return {
      images: [],
      title: "",
      url: "",
      count: 0,
    };
  },

  methods: {
    tweet() {
      let tweetEndpoint = `https://twitter.com/intent/tweet?text=${this.title}%0A${this.url}`;
      window.open(tweetEndpoint);
      this.incrementCounter("twitter");
    },

    pinterest() {
      var elements = document.body.getElementsByTagName("*");
      Array.prototype.forEach.call(elements, (el) => {
        var style = window.getComputedStyle(el, false);
        if (
          style.backgroundImage != "none" &&
          style.backgroundImage.includes("url(")
        ) {
          this.images.push(
            style.backgroundImage.slice(4, -1).replace(/['"]/g, "")
          );
        }
      });
    },

    whatsapp() {
      let message = `https://api.whatsapp.com/send?text=${this.title}%0A${this.url}`;
      window.open(message);
      this.incrementCounter("whatsapp");
    },

    facebook() {
      let message = `https://www.facebook.com/dialog/share?app_id=5303202981&display=popup&href=${this.url}`;
      window.open(message);
      this.incrementCounter("facebook");
    },

    async shareCount() {
      let response = await axios.get(`/fetch-social-share-count`);
      this.count = response.data.count;
    },

    async incrementCounter(platform) {
      try {
        let returnMessage = await axios.post(
          `/increment-social-media-share-click-count`,
          { platform }
        );
      } catch (err) {
        console.log(err.message);
      }
    },
  },

  filters: {
    roundDown(value) {
      return value > 999 ? (value / 1000).toFixed(1) + "k" : value;
    },
  },

  created() {
    this.title = encodeURI(this.post.articleTitle);
    this.url = encodeURI(location.href);
    this.shareCount();
  },

  mounted() {
    let pinterestScript = document.createElement("script");
    pinterestScript.setAttribute("src", "//assets.pinterest.com/js/pinit.js");
    pinterestScript.setAttribute("defer", true);
    document.head.appendChild(pinterestScript);
    this.pinterest();
  },
};
</script>

<style scoped>
p {
  color: inherit;
}

a {
  margin-left: 10px;
}

img {
  width: 42px;
  height: 42px;
}

.share-count {
  /*padding-bottom: 0 !important;*/
}
</style>