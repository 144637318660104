// Convenient/reusable form validation mixins
// Made to work out of the box with Bootstrap Vue

/*======== Email ===============*/
export const email = {
    data () {
        return {
            email: {
                validated: null,
                data: "",
                emailReg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            }
        }
    },

    methods: {
        validateEmail () {
            this.email.validated = this.email.emailReg.test(this.email.data)
        }
    },

    watch: {
        'email.data': function (newData, oldData) {
            if (this.email.emailReg.test(newData)) {
                this.email.validated = true;
            } else if (this.email.data.length == 0) {
                this.email.validated = null
            } else {
                this.email.validated = false;
            }
        },
    }
}

/*========= First Name ===========*/
export const firstName = {
    data() {
        return {
            firstName: {
                validated: null,
                data: "",
            }
        }
    },

    methods: {
        validateFirstName() { 
            this.firstName.validated = (this.firstName.data.length >= 3)
        }
    },

    watch: {
        'firstName.data': function (newData, oldData) {
            if (newData.length < 3 && newData.length > 0) {
                this.firstName.validated = false;
            } else if (newData.length == 0) {
                this.firstName.validated = null;
            } else {
                this.firstName.validated = true;
            }
        },
    }
}

/*========= Comment ===========*/
export const comment = {
    data() {
        return {
            comment: {
                validated: null,
                data: "",
            }
        }
    },

    methods: {
        validateComment () {
            this.comment.validated = this.comment.data.length > 0;
        }
    },

    watch: {
        'comment.data': function (newData, oldData) {
            this.comment.validated = newData.length ? true : null;
        },
    }
}

/*========== Url ========== */

export const url = {
    methods: {
        validateUrl(url) {
            if (url) {
                if (!/^https?:\/\//i.test(url)) {
                    url = 'http://' + url;
                }
            }
            let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
            return { valid: regexp.test(url), url: url };
        },

        validUrl (url) {
            let validate = this.validateUrl(url);
            return validate.valid;
        }
    }
}