<template>
  <div>
    <div v-if="post">
      <!-- Blog Post -->
      <div class="container blog-post my-3 d-lg-block" id="blog-post">
        <hr class="px-0 mt-0 mx-0" />
        <div class="row">
          <div class="col-md-8 pl-md-4">
            <div class="row">
              <div class="col p-md-0 blog-breadcrumbs">
                <span class=""
                  ><router-link class="me-1" to="/blog">Home</router-link></span
                >
                <span class="me-1">></span>
                <span class=""
                  ><router-link class="me-1" to="/blog">Blog</router-link></span
                >
                <span class="me-1">></span>
                <span class=""
                  ><router-link class="me-1" :to="'/' + post.category.slug">{{
                    post.category.title
                  }}</router-link></span
                >
              </div>
            </div>

            <div class="row mt-3">
              <div class="col p-md-0">
                <div v-if="post.show_trusted_message == 1">
                  <p>
                    <show-trusted-message
                      class="pl-0 me-3"
                    ></show-trusted-message>
                  </p>
                </div>

                <!-- Blog Author -->
                <span class="d-inline mt-1" v-if="post.author">
                  <p
                    id="article-author"
                    class="w-100 text-dark post-title mb-0"
                  >
                    Author:
                    {{
                      post.author ? post.author.name : "Amchara Health Retreats"
                    }}
                  </p>
                </span>
              </div>
            </div>

            <!-- Blog Title -->
            <div class="row mt-2">
              <div class="col p-md-0">
                <h1 id="article-title" class="text-dark post-title">
                  {{ post.title }}
                </h1>
              </div>
            </div>
            <!-- Featured Blog Image -->
            <div class="row mt-3 mb-4">
              <div class="col-12 p-0">
                <img
                  :src="'https://storage.googleapis.com/amchara-205620.appspot.com/storage/' + post.featured_image"
                  :alt="post.title"
                  width="100%"
                />
              </div>
            </div>
            <!-- Main Blog Content -->
            <div class="row">
              <div class="col-12 p-md-0 blog-content">
                <div v-html="post.content"></div>
              </div>
            </div>
          </div>

          <div class="col-md-3 offset-1 blog-sidebar d-none d-md-block">
            <side-bar
              :related-content="relatedContent"
              :categories="categories"
              :ad_id="post.ad_id"
            ></side-bar>
          </div>
        </div>
      </div>

      <!-- Should only be shown on certain posts, convert to snippet. -->
      <!-- front.partials.hormone-guide-signup -->

      <div
        class="fixed-bottom"
        id="social-media-share-container"
        style="max-width: 260px"
      >
        <social-media-share :post="post"></social-media-share>
      </div>

      <!-- Comment Section -->
      <div class="container mt-5 mb-5">
        <!-- <div class="row" id="blog-comment-form-container">
          <blog-comment-form :post="post">
            <b-modal
              id="login-modal"
              centered
              hide-header
              hide-footer
              body-class="p-0"
            >
              <div class="card">
                <div class="card-header text-center bg-primary text-white">
                  <b>User Area</b>
                </div>
                <div class="card-body">
                  <form
                    class="form-signin"
                    action="/user-area/login"
                    method="post"
                    id="login-form"
                  >
                    <div class="text-center my-3">
                      <p>
                        We have done away with passwords! This is a more secure
                        and easier to use method of signing than traditional
                        passwords. Enter your email address below & we'll
                        instantly send you a magical sign-in link.
                      </p>
                    </div>
                    <div class="form-row my-5">
                      <div class="col">
                        <input
                          type="email"
                          name="email"
                          class="form-control"
                          placeholder="Email address"
                          required
                        />
                      </div>
                    </div>

                    <div class="form-row mb-2">
                      <div class="col-md-6 mx-auto">
                        <button
                          type="submit"
                          class="g-recaptcha btn btn-info btn-lg btn-block mt-4"
                          data-callback="onSubmit"
                        >
                          <i class="fas fa-magic me-1"></i>SEND LOGIN LINK
                        </button>
                      </div>
                    </div>

                    <div class="form-row mb-5">
                      <div class="col-md-6 mx-auto text-center">
                        <router-link
                          class="mt-2 text-center"
                          to="/user-area/register"
                          >Signup</router-link
                        >
                      </div>
                    </div>
                  </form>
                </div>
                <div class="card-footer text-center pt-4">
                  <p>
                    <i>
                      By logging in to Amchara you are accepting the updated,
                      GDPR compliant Data Protection Policy
                      <router-link to="/privacy" target="_blank"
                        >that can be found here.</router-link
                      >
                    </i>
                  </p>
                </div>
              </div>
            </b-modal>
          </blog-comment-form>
        </div> -->
        <!-- "back to main blog" button -->
        <div class="row justify-content-center">
          <div class="col-md-4 text-center">
            <router-link
              :to="'/' + post.category.slug"
              class="btn btn-primary mt-5 px-5 text-center dark-green-gradient"
              >Return to Blog Directory</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <div class="" v-else>Loading</div>
    <!-- Newsletter signup -->
    <newsletter-cta></newsletter-cta>

    <div class="d-sm-block d-md-none">
      <blog-search></blog-search>
    </div>
  </div>
</template>

<script>
import NewsletterCta from "../pages/page-partials/NewsletterCta";
import BlogSearch from "./blog-partials/BlogSearch";
import SideBar from "./blog-partials/SideBar";
import BlogCommentForm from "./blog-partials/BlogCommentForm";
import SocialMediaShare from "./blog-partials/SocialMediaShare";
import ShowTrustedMessage from "./blog-partials/ShowTrustedMessage";

export default {
  // props: ["post", "relatedContent", "categories"],
  metaInfo() {
    return {
      vmid: this.$route.path,
      name: this.$route.path,
      title: this.seoTitle,
      content: this.seoDescription,
      // titleTemplate: null,
    };
  },
  data() {
    return {
      post: null,
      relatedContent: [],
      categories: [],

      seoTitle: undefined,
      seoDescription: undefined,
    };
  },

  methods: {
    fetchPost() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/blog/posts/" + this.$route.params.id
        )
        .then(({ data }) => {
          this.post = data.post;
          if (this.post.embedded_scripts) {
            this.parseScripts(this.post.embedded_scripts);
          }
          this.relatedContent = data.related;
          this.categories = data.categories;

          this.seoTitle = this.post.seo_title;
          this.seoDescription = this.post.seo_description;
        });
    },

    // This is a hack to interpolate JS from the db in the page. Obviously
    // this is not an ideal approach, but it appears to work. We only take
    // what's between script tags (or without them), so include urls don't
    // work but also won't cause problems.
    parseScripts(source) {
      let sourceWithoutTags = '';

      if (source.includes('script')) {
        let re = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
        let match;

        while (match = re.exec(source)) {
          sourceWithoutTags += match[1];
        }
      } else {
        sourceWithoutTags = source;
      }

      if (sourceWithoutTags.length) {
        let newScriptTag = document.createElement('script');
        let currentScriptTags = document.getElementsByTagName('script')[0];

        newScriptTag.text = sourceWithoutTags;
        currentScriptTags.parentNode.insertBefore(newScriptTag, currentScriptTags);
      }
    }
  },
  created() {
    this.$store.isBlogPost = true;
  },
  beforeDestroy() {
    this.$store.isBlogPost = false;
  },
  mounted() {
    this.fetchPost();
    // function onSubmit(token) {
    //   document.getElementById("login-form").submit();
    // }
    // var articleTitle = this.post.title;
    // // var googleDataSiteKey = this.config();
    // var googleDataSiteKey = "";
    // var postId = this.post.id;
    // var loggedIn = this.$loggedIn;
  },
  components: {
    BlogSearch,
    SideBar,
    NewsletterCta,
    SocialMediaShare,
    BlogCommentForm,
    ShowTrustedMessage,
  },
};
</script>

<style>
  img {
    width: 100%;
  }

  .blog-content > div > h2 {
    margin-top: 1em;
    margin-bottom: 1em;
  }
</style>