<template>
  <b-col v-if="showForm" v-on-clickaway="closeForm">
    <b-form-row class="mt-md-5">
      <b-form-group class="col-md-4">
        <b-form-input
          placeholder="First Name"
          :state="firstName.validated"
          :disabled="commented"
          v-model="firstName.data"
        ></b-form-input>
        <b-form-invalid-feedback
          >Enter at least 3 letters</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group class="col-md-4">
        <b-form-input
          placeholder="Email"
          :state="email.validated"
          :disabled="commented"
          v-model="email.data"
        ></b-form-input>
        <b-form-invalid-feedback
          >Enter a valid email address</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group class="col-md-4">
        <b-form-input
          type="url"
          placeholder="Website"
          :disabled="commented"
          v-model="formData.website"
        ></b-form-input>
      </b-form-group>
    </b-form-row>

    <b-form-row class="mt-md-3">
      <b-form-group class="col">
        <b-form-textarea
          :rows="3"
          :max-rows="6"
          placeholder="Leave a comment"
          class="mb-2"
          :state="comment.validated"
          :disabled="commented"
          v-model="comment.data"
        ></b-form-textarea>
        <b-form-invalid-feedback
          >Comment field is blank</b-form-invalid-feedback
        >
      </b-form-group>
    </b-form-row>

    <b-form-row class="justify-content-between mt-1">
      <b-col md="4">
        <b-button
          @click.prevent="addComment"
          :size="'lg'"
          :variant="'primary'"
          class="mt-3 px-md-5 float-md-right text-white dark-green-gradient h2 btn-block"
        >
          Reply
        </b-button>
      </b-col>
    </b-form-row>
  </b-col>
</template>

<script>
import axios from "axios";
import { email, firstName, comment } from "../formValidation";
import { mixin as clickaway } from "vue-clickaway";

export default {
  props: ["commentIn"],
  mixins: [clickaway, email, firstName, comment],
  data() {
    return {
      replyComment: null,
      showForm: false,
      commented: false,
      formData: {
        website: "",
      },
      btnBlock: false,
    };
  },

  methods: {
    async addComment() {
      try {
        if (this.validate() && !this.commented) {
          let response = await axios.post("/blog/add-comment", {
            blog_id: this.commentIn.blog_id,
            blogger_name: this.firstName.data,
            blogger_email: this.email.data,
            blogger_website: this.formData.website,
            comment: this.comment.data,
            reply_to: this.commentIn.id,
          });

          if (response.data.success) {
            this.$emit("replyAdded", response.data.comment);
            this.resetForm();
            this.showForm = false;
          }
        } else {
          alert("There was a problem, please try again");
        }
      } catch (err) {
        console.log("BlogCommentReplyForm", err.message);
      }
    },

    // Submission validation
    validate() {
      this.validateFirstName();
      this.validateEmail();
      this.validateComment();

      return (
        this.firstName.validated &&
        this.email.validated &&
        this.comment.validated
      );
    },

    resetForm() {
      this.firstName.data = this.email.data = this.comment.data = this.formData.website =
        "";
      this.firstName.validated = this.email.validated = this.comment.validated = null;
    },

    closeForm() {
      this.showForm = false;
    },
  },

  created() {
    eventBus.$on("updateReplyComment", (replyComment) => {
      if (replyComment.id == this.commentIn.id) {
        this.showForm = !this.showForm;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
$placeholder-font-size: 1rem;

div.comment {
  white-space: pre;
}

.blogger-name {
  cursor: pointer;
}
</style>