<template>
  <div class="col-md-8 comment-section pt-4 pl-md-4 pr-md-5">
    <h2 class="text-dark-green">Leave us a comment</h2>
    <b-form-row>
      <b-col>
        <b-btn
          class="mt-2"
          v-b-modal="'login-modal'"
          variant="outline-primary"
          v-if="!authenticated"
          >Login to leave a comment</b-btn
        >
      </b-col>
    </b-form-row>
    <div class="comment-count mb-4">
      <span class="font-weight-bold d-inline-block">{{ commentCount }}</span>
    </div>
    <b-form-row class="mt-md-5" v-if="authenticated">
      <b-form-group class="col-md-4">
        <b-form-input
          type="text"
          maxlength="64"
          placeholder="First Name"
          :state="firstName.validated"
          :disabled="commented"
          v-model="firstName.data"
        ></b-form-input>
        <b-form-invalid-feedback
          >Enter at least 3 letters</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group class="col-md-4">
        <b-form-input
          type="text"
          maxlength="64"
          placeholder="Email"
          :state="email.validated"
          :disabled="commented"
          v-model="email.data"
        ></b-form-input>
        <b-form-invalid-feedback
          >Enter a valid email address</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group class="col-md-4">
        <b-form-input
          type="url"
          maxlength="64"
          placeholder="Website"
          :disabled="commented"
          v-model="formData.website"
        ></b-form-input>
      </b-form-group>
    </b-form-row>

    <b-form-row class="mt-md-3" v-if="authenticated">
      <b-form-group class="col">
        <b-form-textarea
          :rows="3"
          :max-rows="6"
          placeholder="Leave a comment"
          class="mb-2"
          :state="comment.validated"
          :disabled="commented"
          v-model="comment.data"
        ></b-form-textarea>
        <b-form-invalid-feedback
          >Comment field is blank</b-form-invalid-feedback
        >
      </b-form-group>
    </b-form-row>

    <b-form-row class="justify-content-between mt-1" v-if="authenticated">
      <b-col md="5" class="pt-2 pt-md-0">
        <b-button
          @click.prevent="addComment"
          :variant="'primary'"
          class="px-lg-5 mt-3 mt-md-1 float-md-right text-white dark-green-gradient h2 btn-block"
          >Submit Comment</b-button
        >
      </b-col>
    </b-form-row>

    <b-form-row class="comment-list mt-3 pl-3">
      <b-col v-show="commented">
        <p class="text-success">Comment added!</p>
      </b-col>
      <b-col
        md="11"
        class="comment-container"
        :id="'comment-' + comment.id"
        v-for="(comment, i) in commentList"
        :key="comment.id"
      >
        <div class="name-date-container mb-1">
          <span
            @click.prevent="goToSite(comment.website)"
            class="text-primary"
            :class="{ 'blogger-name': validUrl(comment.website) }"
            >{{ comment | displayName }}</span
          >
        </div>
        <div class="comment mb-2">{{ comment.comment }}</div>
        <div
          class="comment-actions pl-4"
          :class="{ 'mb-3': comment.replies.length > 0 }"
        >
          <div class="comment-reply" v-if="authenticated">
            <a href="#" @click.prevent="reply(comment, i)">
              <i class="fas fa-reply fa-xs text-secondary"></i>
              <span class="text-secondary small">Reply</span>
            </a>
          </div>
          <blog-comment-reply-form
            @replyAdded="addReply(comment, $event)"
            :commentIn="comment"
          ></blog-comment-reply-form>
          <b-form-row>
            <b-col
              md="11"
              class="pl-md-5 pl-2 mt-2 comment-container"
              v-for="(reply, i) in comment.replies"
              :id="'comment-' + reply.id"
              :key="reply.id"
            >
              <div class="name-date-container mb-1">
                <span
                  class="text-primary"
                  :class="{
                    'blogger-name': validUrl(reply.website),
                    'text-dark-green': reply.is_admin,
                    'font-italic': reply.is_admin,
                  }"
                  @click.prevent="goToSite(reply.website)"
                  >{{ reply | displayName }}</span
                >
              </div>
              <div
                class="comment"
                :class="{ 'mb-2': i != comment.replies.length - 1 }"
              >
                {{ reply.comment }}
              </div>
              <hr v-show="i != comment.replies.length - 1" />
            </b-col>
          </b-form-row>
          <hr />
        </div>
      </b-col>
    </b-form-row>
    <slot></slot>
  </div>
</template>

<script>
import axios from "axios";
import BlogCommentReplyForm from "./BlogCommentReplyForm.vue";

import { email, firstName, comment, url } from "../formValidation";

export default {
  props: ["post"],
  mixins: [email, firstName, comment, url],
  data() {
    return {
      postId: "",
      formData: {
        website: "",
      },
      userLoginLink: "",
      returnAddress: "",
      commentList: [],
      commented: false,
      replyComment: null,
      authenticated: false,
    };
  },

  computed: {
    commentCount() {
      let count = this.commentList.length;
      this.commentList.forEach((comment) => (count += comment.replies.length));
      return `${count} Comment${count == 1 ? "" : "s"}`;
    },
  },

  methods: {
    async addComment() {
      try {
        if (this.validate() && !this.commented) {
          let response = await axios.post("/blog/add-comment", {
            blog_id: this.postId,
            blogger_name: this.firstName.data,
            blogger_email: this.email.data,
            blogger_website: this.formData.website,
            comment: this.comment.data,
          });

          if (response.data.success) {
            response.data.comment.replies = [];
            this.commentList.unshift(response.data.comment);
            this.resetForm();
            this.commented = true;
          }
        } else {
          alert("There was a problem, please try again");
        }
      } catch (err) {
        console.log("BlogCommentForm", err.message);
      }
    },

    reply(comment, i) {
      comment.updateIndex = i;
      eventBus.$emit("updateReplyComment", comment);
    },

    addReply(comment, reply) {
      this.getReplies(comment, reply);
    },

    async getComments() {
      try {
        let response = await axios.get(
          "/blog-post/" + this.post.id + "/fetch-comments"
        );
        this.commentList = response.data.blog_comments;
      } catch (err) {
        console.log(err.message);
      }
    },

    async getReplies(comment, reply) {
      try {
        let response = await axios.get(`/blog/get-replies/${comment.id}`);
        comment.replies = response.data;
      } catch (err) {
        console.log(err.message);
      }
    },

    // Submission validation
    validate() {
      this.validateFirstName();
      this.validateEmail();
      this.validateComment();

      return (
        this.firstName.validated &&
        this.email.validated &&
        this.comment.validated
      );
    },

    resetForm() {
      this.firstName.data = this.email.data = this.comment.data = this.formData.website =
        "";
      this.firstName.validated = this.email.validated = this.comment.validated = null;
    },

    async goToLoginPage(
      event,
      specificComment = "#blog-comment-form-container"
    ) {
      this.returnPath = `${window.location.pathname}${specificComment}`;

      let returnAddressSet = await axios.post("/user-area/set-return-path", {
        return_path: this.returnPath,
      });
      window.location = "/user-area/login";
    },

    goToSite(url) {
      if (url) {
        let validate = this.validateUrl(url);
        if (validate.valid) {
          window.open(validate.url, "_blank");
        }
      }
    },
  },

  created() {
    this.postId = this.post.id;
    this.googleDataSiteKey = "";
    this.getComments();
    this.authenticated = false;
  },

  filters: {
    displayName: (comment) => {
      if (comment.blog_author_id && comment.blog_author) {
        return `by blog author ${comment.blog_author.first_name || ""} ${
          comment.blog_author.last_name || ""
        }`;
      } else if (comment.is_admin) {
        return `Amchara Admin - ${comment.blogger_name}`;
      } else {
        return comment.blogger_name;
      }
    },
  },

  components: {
    BlogCommentReplyForm,
  },
};
</script>

<style lang="scss" scoped>
$placeholder-font-size: 1rem;
$reply-size: 12px;

div.comment {
  white-space: pre-line;
}

div.comment-count {
  border-bottom: 2px solid #5d9d7f;

  span {
    border-bottom: 5px solid #8cc63f;
    line-height: 50px;
    height: 50px;
    transform: translate(0, 3px);
  }
}

.blogger-name {
  cursor: pointer;
}
</style>