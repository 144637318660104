<template>
    <div id="show-trusted-message">
        <b-btn
            v-b-modal.evidence-message
            variant="outline-green"
            size="sm"
        >
            <i class="fas fa-check fa-sm"></i> Evidence Based
        </b-btn>
        <!-- Modal Component -->

        <b-modal
            id="evidence-message"
            hide-header
            hide-footer
            centered
            ref="trustedMessage"
        >
            <b-row>
                <b-col>
                    <span @click="hideModal" class="hide-modal-icon">
                        <i class="fas fa-times float-right"></i>
                    </span>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <p>
                        This article is based on scientific evidence,
                        written by experts and fact checked by experts.<br><br>

                        Our team of licensed nutritionists and dietitians
                        strive to be objective, unbiased and honest, and
                        to present both sides of the argument.
                    </p>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    export default {
        methods: {
            hideModal () {
                this.$refs.trustedMessage.hide();
            }
        }
    }
</script>

<style lang="scss" scoped>
    span.hide-modal-icon:hover {
        cursor: pointer;
    }

    button {
        border-radius: 40px;
        border-color: #667c89!important;
        color: #667c89!important;
    }

    button:hover {
        background-color: #667c89!important;
        color: #fff!important;
    }
</style>